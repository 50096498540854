import React from 'react';
import { MainDataItem, VideoData } from '../App';
import Chat from './Chat';

interface PredictProps {
  videosData: VideoData[];
  loading: boolean;
}

const Predict: React.FC<PredictProps> = ({ videosData, loading }) => {
  console.log('videosData:', videosData);

  // Utility function to decode HTML entities
  const decodeHtmlEntities = (text: string) => {
    return text.replace(/&#(\d+);/g, (match: string, dec: string) => {
      return String.fromCharCode(parseInt(dec, 10));
    });
  };

  return (
    <div className="flex  h-full">
      <div className="pr-4 mr-2 w-4/12 flex flex-col">
        {' '}
        {/* Add flex and flex-col classes */}
        {/* <h2 className="text-white text-xl font-semibold mb-4">My Videos</h2> */}
        <div className=" bg-slate-800 opacity-95 text-white font-semibold flex items-center py-2 px-4 rounded-lg mb-2 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-4 h-4 mr-2"
          >
            <path d="M5.85 3.5a.75.75 0 0 0-1.117-1 9.719 9.719 0 0 0-2.348 4.876.75.75 0 0 0 1.479.248A8.219 8.219 0 0 1 5.85 3.5ZM19.267 2.5a.75.75 0 1 0-1.118 1 8.22 8.22 0 0 1 1.987 4.124.75.75 0 0 0 1.48-.248A9.72 9.72 0 0 0 19.266 2.5Z" />
            <path
              fillRule="evenodd"
              d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0 24.583 24.583 0 0 0 4.83-1.244.75.75 0 0 0 .298-1.205 8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Z"
              clipRule="evenodd"
            />
          </svg>

          <p>There's a 72 hour delay in getting the metrics! </p>
        </div>
        <div className="h-full overflow-y-auto">
          {videosData.length === 0 ? (
            <p className="text-white">Your channel doesnt have any videos.</p>
          ) : (
            videosData.map((video) => (
              <div
                key={video.title}
                className="glassmorphism p-4 mb-4 rounded-lg bg-[#1a1a2e] bg-opacity-80 backdrop-blur-lg transition duration-300 ease-in-out hover:shadow-lg "
              >
                <img
                  src={video.thumbnails.medium.url}
                  alt="Video Thumbnail"
                  className="w-full h-auto mb-2 rounded-lg"
                />
                <h4 className="text-lg font-bold text-white">
                  {decodeHtmlEntities(video.title)}{' '}
                  {video.isShort && (
                    <span className="text-sm text-purple-300">- Short</span>
                  )}
                </h4>
                <p className="text-sm text-gray-300 mb-4">
                  {video.description.split('\n').slice(0, 2).join('\n')}
                </p>

                <div className="flex flex-row justify-between text-sm text-white">
                  <div className="w-1/2">
                    <p>
                      <strong>Views:</strong>{' '}
                      <span className=" text-purple-300 "> {video.views} </span>{' '}
                    </p>
                    <p>
                      <strong>Likes:</strong> {video.likes}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p>
                      <strong>Subs Gained:</strong>{' '}
                      <span className=" text-green-300 ">
                        {' '}
                        {video.subscribersGained}{' '}
                      </span>{' '}
                    </p>

                    <p>
                      <strong>Dislikes:</strong> {video.dislikes}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="pl-4 w-8/12">
        {' '}
        <Chat />
      </div>
    </div>
  );
};

export default Predict;
