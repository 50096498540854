/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import thumbnails from './thumbnails'; // Adjust the path as needed

const Gallery = () => {
  // Function to handle copying text to clipboard
  const [copiedId, setCopiedId] = React.useState<null | number>(null);

  const copyToClipboard = (text: string, id: number) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedId(id);
        setTimeout(() => {
          setCopiedId(null);
        }, 2000);
      })
      .catch((err) => {
        console.error('Error in copying text: ', err);
      });
  };

  return (
    <div className="">
      <p className="text-white py-2 font-bold mb-2  break-words">
        🖼️ Use these image prompts for creating similar thumbnails , or reuse
        these images directly with your face swapped in!
      </p>

      <div className="grid grid-cols-3 gap-4">
        {thumbnails.map(({ id, image, textPrompt }) => (
          <div
            key={id.toString()}
            className=" items-center  border-2 border-gray-800 rounded-lg overflow-hidden relative "
          >
            <img src={image} alt={`Thumbnail ${id}`} className="w-full" />
            <div className=" flex justify-center items-center relative w-full ">
              {/* <div className="bg-gray-200 p-2 rounded-lg">{textPrompt}</div> */}
              <textarea
                className="bg-gray-900 p-2 rounded-lg w-full text-gray-400 text-sm "
                value={textPrompt}
                rows={3}
                readOnly
              />
              <button
                className="mt-2 px-2 py-1 mx-2 bg-blue-500 text-white rounded h-fit flex items-center space-x-2  absolute right-1 bottom-1  "
                key={id}
                onClick={() => copyToClipboard(textPrompt, id)}
              >
                {copiedId === id ? 'Copied!' : 'Copy'}
                {copiedId === id ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5 ml-2"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                      clipRule="evenodd"
                    />
                    <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5 ml-2"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.5 3A1.501 1.501 0 0 0 9 4.5h6A1.5 1.5 0 0 0 13.5 3h-3Zm-2.693.178A3 3 0 0 1 10.5 1.5h3a3 3 0 0 1 2.694 1.678c.497.042.992.092 1.486.15 1.497.173 2.57 1.46 2.57 2.929V19.5a3 3 0 0 1-3 3H6.75a3 3 0 0 1-3-3V6.257c0-1.47 1.073-2.756 2.57-2.93.493-.057.989-.107 1.487-.15Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default Gallery;
