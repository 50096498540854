import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureAxios from './axiosConfig';
import PostLoginScreen from './components/PostLoginScreen';
import posthog from 'posthog-js';
import Login from './components/Login';
import LinkChannel from './components/LinkChannel';

posthog.init('phc_zGUSfOGfVvGzh8Qs5lM8jFt7s4ysoHoCoYiXeYRDBCk', {
  api_host: 'https://us.posthog.com',
});

// additional scopes to be added later https://www.googleapis.com/auth/yt-analytics-monetary.readonly

configureAxios();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Login
              scope="email"
              // prod
              clientId="738447064762-c8pch5opl4lbvobgo0phadreegi47aho.apps.googleusercontent.com"
              // stage clientId="364482089935-0qm39l1tp3sn2464s120tr48klqup57c.apps.googleusercontent.com"
            />
          }
        />
        <Route
          path="/linkchannel"
          element={
            <LinkChannel
              scope="https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly  email"
              // prod
              clientId="738447064762-c8pch5opl4lbvobgo0phadreegi47aho.apps.googleusercontent.com"
              // stage
              // clientId="364482089935-0qm39l1tp3sn2464s120tr48klqup57c.apps.googleusercontent.com"
            />
          }
        />
        {/* <Route
          path="/"
          element={
            <GoogleLogin
              scope="https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly  email"
              clientId="738447064762-c8pch5opl4lbvobgo0phadreegi47aho.apps.googleusercontent.com"
            />
          }
        /> */}
        {/* postlogin is the 'redirecting screen , change it later' */}
        <Route path="/login3" element={<PostLoginScreen />} />
        <Route path="/dashboard" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
