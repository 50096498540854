import React, { useState } from 'react';
import AppLogo from '.././assets/fanfuellogo.png';
import { MainDataItem } from '../App';
import { useNavigate } from 'react-router-dom';
import useAppStore from '../zustandstore/appstate';

interface NavbarV2Props {
  userData: MainDataItem | null;
}

const NavbarV2: React.FC<NavbarV2Props> = ({ userData }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const { isYouTubeDataLatest, setIsYouTubeDataLatest } = useAppStore();
  const handleLogout = () => {
    localStorage.clear();
    setShowDropdown(false);
    navigate('/');
    // you might want to navigate user to login page after this
  };

  return (
    <div className="navbar bg-black p-4 flex justify-between items-center">
      <img className="w-14 h-14 rounded-md" src={AppLogo} alt="Logo" />
      {isYouTubeDataLatest ? null : (
        <div className="flex justify-center items-center ml-2 glassmorphism px-4 py-1 rounded-full ">
          <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
          <p className=" text-white rounded-md w-fit mx-2 text-sm px-2 ">
            Fetching your latest Stats
          </p>
        </div>
      )}
      {userData && (
        <div className="relative ">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <img
              className="w-10 h-10 rounded-full border-2 border-gray-800 "
              src={userData.snippet.thumbnails.medium.url}
              alt="User Profile"
              // onClick={() => setShowDropdown(!showDropdown)}
            />
            {/* <p className="text-white ml-2">{userData.snippet.title}</p> */}
          </div>
          {showDropdown && (
            <div className="absolute right-0 mt-2    glassmorphism p-1 px-2 z-50 ">
              <button
                className="block w-full px-2 py-2 text-sm text-white  "
                onClick={handleLogout}
              >
                Logout
              </button>
              {/* <button
                className="block w-full px-2 py-2 text-sm text-white  "
                onClick={handleLogout}
              >
                Settings
              </button> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NavbarV2;
