/* eslint-disable react/prop-types */
/* eslint-disable no-var */
/* eslint-disable react/no-unescaped-entities */
import React, {  useState } from 'react';
import axios from 'axios';
import { MainDataItem } from '../App';
import useLoaderStore from '../zustandstore/store';
import { act } from 'react-dom/test-utils';
import swapped_image from '../assets/thumbnail/swap-example.png';
import gallery from '../assets/thumbnail/gallery.png';
import FaceSwap from './thumbnailcomponents/FaceSwap';
import Gallery from './thumbnailcomponents/Gallery';
import photopea_logo from '../assets/logos/Photopea_logo.svg.png'

interface ThumbnailCreatorProps {
  userData: MainDataItem | null;
}

enum TabNames {
  ThumbnailDesigner = 'thumbnailDesigner',
  DirectGenerate = 'directGenerate',
  FaceSwap = 'faceSwap',
  AttentionHeatmap = 'attentionHeatmap',
  Gallery = 'gallery',
}

const ThumbnailCreator = ({ userData }: ThumbnailCreatorProps) => {
  const [videotitle, setVideoTitle] = useState<string>('');
  // const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [thumbnailUrls, setThumbnailUrls] = useState<{
    image1?: string;
    image2?: string;
  }>({});

  const [comments, setComments] = useState<string>('');
  const [thinkingOutLoud, setThinkingOutLoud] = useState<string>('');
  const [dallePrompt, setDallePrompt] = useState<string>('');
  const { loading, setLoading } = useLoaderStore();
  const [activeTab, setActiveTab] = useState<TabNames>(
    TabNames.ThumbnailDesigner
  );
  const [directPrompt, setDirectPrompt] = useState<string>('');

  const fetchGPT4Metadata = async () => {
    const gpt4Url = '/thumbnailpromptcreate';
    setLoading(true);

    if (videotitle) {
      axios
        .post(gpt4Url, { title: videotitle, comments: comments })
        .then((response) => {
          setThinkingOutLoud(response.data.thinking_out_loud);
          setDallePrompt(response.data.dalle_prompt);
          setLoading(false);
        })
        .catch((error) => {
          alert('Something went wrong. Please reset and try again:');
          console.error('Error:', error);
          setLoading(false);
        });
    } else {
      alert('Please enter a valid video title');
      setLoading(false);
    }
  };

  const fetchDalleImage = async () => {
    const dalleUrl = '/thumbnailcreate'; // Replace with your actual DALL-E API endpoint
    setThumbnailUrls({});
    setLoading(true);

    const imageprompt =
      activeTab === 'thumbnailDesigner' ? dallePrompt : directPrompt;

    axios
      .post(dalleUrl, { imageprompt: imageprompt })
      .then((response) => {
        setThumbnailUrls({
          image1: response.data.thumbnailUrl.image1 || '',
          image2: response.data.thumbnailUrl.image2 || '',
        });
        setLoading(false);
      })
      .catch((error) => {
        alert('Something went wrong. Please reset and try again:');
        console.error('Error:', error);
        setLoading(false);
      });
  };

  const downloadImage = () => {
    // const link = document.createElement('a');
    // link.href = thumbnailUrl;
    // link.target = '_blank';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    const link1 = document.createElement('a');
    const link2 = document.createElement('a');
    link1.href = thumbnailUrls.image1 || '';
    link2.href = thumbnailUrls.image2 || '';
    link1.target = '_blank';
    link2.target = '_blank';
    document.body.appendChild(link1);
    document.body.appendChild(link2);
    link1.click();
    link2.click();
    document.body.removeChild(link1);
    document.body.removeChild(link2);
  };

  const editWithPhotopea = (url: string) => {
        // Open the compressed image in Photopea
        var photopeaUrl = "https://www.photopea.com#" + encodeURIComponent(JSON.stringify({
          "files": [url]
        }));
  
        window.open(photopeaUrl);

  };
  const resetState = () => {
    // Reset all states to their initial values
    setVideoTitle('');
    // setThumbnailUrl('');
    setThumbnailUrls({});
    setComments('');
    setThinkingOutLoud('');
    setDallePrompt('');
  };

  return (
    <div className="">
      {/* tab headers   */}
      <div className="flex  text-white text-xl font-semibold ">
        <button
          className={`py-2 px-4 ${
            activeTab === TabNames.ThumbnailDesigner
              ? 'border-b-2 border-blue-500 bg-slate-800 rounded-t-md'
              : ''
          }`}
          onClick={() => setActiveTab(TabNames.ThumbnailDesigner)}
        >
          👩🏼‍🎨 Thumbnail Designer
        </button>
        <button
          className={`py-2 px-4 ${
            activeTab === TabNames.DirectGenerate
              ? 'border-b-2 border-blue-500 bg-slate-800 rounded-t-md'
              : ''
          }`}
          onClick={() => setActiveTab(TabNames.DirectGenerate)}
        >
          🏎️ Direct Generate
        </button>
        <button
          className={`py-2 px-4 ${
            activeTab === TabNames.FaceSwap
              ? 'border-b-2 border-blue-500 bg-slate-800 rounded-t-md'
              : ''
          }`}
          onClick={() => setActiveTab(TabNames.FaceSwap)}
        >
          🎭 Face Swap
        </button>
        <button
          className={`py-2 px-4 ${
            activeTab === TabNames.Gallery
              ? 'border-b-2 border-blue-500 bg-slate-800 rounded-t-md'
              : ''
          }`}
          onClick={() => setActiveTab(TabNames.Gallery)}
        >
          🖼️ Thumbnail Gallery
        </button>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-center p-4 mx-auto border-t-2 border-gray-700">
        <div className="flex-grow   h-full lg:w-1/2">
          {/* Tab content */}
          {activeTab === 'thumbnailDesigner' && (
            <div className="">
              <div className="text-gray-400 mb-4 space-y-3">
                <p className="text-lg text-gray-50 ">
                  👩🏼‍🎨 <strong>Let's create your thumbnail!</strong>
                </p>
                <ul className="list-disc list-inside">
                  <li>
                    <strong>Describe your video idea</strong> and any special
                    details you want to highlight in the thumbnail, e.g.,
                    vertical split, no text, shocked face on the bottom right,
                    etc.
                  </li>
                  <li>
                    We'll generate an <strong>image prompt</strong> for you to
                    review and tweak as needed.
                  </li>
                  <li>
                    Happy with the prompt? Click <strong>'Generate'</strong>
                    to see your custom thumbnail come to life!
                  </li>
                </ul>
                <p className="text-lg text-gray-50 ">
                  🎨 <strong>Feeling creative?</strong>
                </p>
                <ul className="list-disc list-inside">
                  <li>
                    Jump over to the <strong>'Direct Generate'</strong> tab to
                    use your own prompt for instant thumbnail creation.
                  </li>
                </ul>
              </div>

              <input
                className="w-full h-12 pl-4 pr-4 mb-4 rounded-lg bg-gray-700 text-white text-lg"
                type="text"
                placeholder="Enter Video Title / Idea"
                value={videotitle}
                onChange={(e) => setVideoTitle(e.target.value)}
              />

              <input
                className="w-full h-12 pl-4 pr-4 mb-4 rounded-lg bg-gray-700 text-white text-lg"
                type="text"
                placeholder="Enter Instructions (Optional)"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />

              <button
                className="w-full bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg shadow transition-colors"
                onClick={fetchGPT4Metadata}
              >
                Generate Prompt
              </button>

              {dallePrompt && (
                <div>
                  <textarea
                    className="w-full h-44 pl-4 pr-4 mt-4 rounded-lg bg-gray-700 text-white text-lg"
                    rows={8}
                    value={dallePrompt}
                    onChange={(e) => setDallePrompt(e.target.value)}
                  ></textarea>
                  <button
                    className=" flex items-center justify-center w-full bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg shadow mt-4 transition-colors"
                    onClick={fetchDalleImage}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5 mr-1"
                    >
                      <path d="M15.993 1.385a1.87 1.87 0 0 1 2.623 2.622l-4.03 5.27a12.749 12.749 0 0 1-4.237 3.562 4.508 4.508 0 0 0-3.188-3.188 12.75 12.75 0 0 1 3.562-4.236l5.27-4.03ZM6 11a3 3 0 0 0-3 3 .5.5 0 0 1-.72.45.75.75 0 0 0-1.035.931A4.001 4.001 0 0 0 9 14.004V14a3.01 3.01 0 0 0-1.66-2.685A2.99 2.99 0 0 0 6 11Z" />
                    </svg>
                    Sounds Good? Generate Image!
                  </button>
                </div>
              )}
            </div>
          )}
          {activeTab === 'directGenerate' && (
            <div className="">
              <textarea
                className="w-full h-44 p-4 mt-4 rounded-lg bg-gray-700 text-white text-lg"
                rows={8}
                value={directPrompt}
                onChange={(e) => setDirectPrompt(e.target.value)}
                placeholder="Enter Direct Prompt, refer gallery tab for examples"
              ></textarea>
              <button
                className=" flex items-center justify-center w-full bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg shadow mt-4 transition-colors"
                onClick={fetchDalleImage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 mr-1"
                >
                  <path d="M15.993 1.385a1.87 1.87 0 0 1 2.623 2.622l-4.03 5.27a12.749 12.749 0 0 1-4.237 3.562 4.508 4.508 0 0 0-3.188-3.188 12.75 12.75 0 0 1 3.562-4.236l5.27-4.03ZM6 11a3 3 0 0 0-3 3 .5.5 0 0 1-.72.45.75.75 0 0 0-1.035.931A4.001 4.001 0 0 0 9 14.004V14a3.01 3.01 0 0 0-1.66-2.685A2.99 2.99 0 0 0 6 11Z" />
                </svg>
                Directly Generate
              </button>
            </div>
          )}
          {activeTab === TabNames.FaceSwap && (
            // link to discord button
            <div className="text-gray-400 mb-4 space-y-3">
              <img
                src={swapped_image}
                alt="swapped_image"
                className="w-full h-full rounded-lg object-cover"
              />

              <FaceSwap />
            </div>
          )}
          {activeTab === TabNames.Gallery && (
            // link to discord button
            <div className="text-gray-400 mb-4 space-y-3">
              <Gallery />
              {/* <img
                src={gallery}
                alt="swapped_image"
                className="w-full h-full rounded-lg object-cover"
              />
              <p className="text-lg text-gray-50 ">
                👩🏼‍🎨 <strong>Face Swap</strong>
              </p>
              <ul className="list-disc list-inside">
                <li>
                  Head over to our discord and
                  <strong> #thumbnails channel</strong> .
                </li>
              </ul>
              <button
                className="w-full bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg shadow transition-colors"
                onClick={() =>
                  window.open('https://discord.gg/b98YAgNNV8', '_blank')
                }
              >
                Head over to Discord
              </button> */}
            </div>
          )}
        </div>

        {/* thumbnail preview */}

        {(activeTab === TabNames.ThumbnailDesigner ||
          activeTab === TabNames.DirectGenerate) && (
          <div className="flex-shrink-0 ml-4 lg:w-1/2 ">
            <div className="p-4 mb-4 bg-gray-700 rounded-lg shadow-lg border-4 border-dashed border-gray-500 flex items-center justify-center">
              <div
                style={{ width: '100%', paddingTop: '57.73%' }}
                className="relative"
              >
                {thumbnailUrls.image1 ? (
                  <img
                    src={thumbnailUrls.image1}
                    alt="Thumbnail"
                    className="absolute top-0 left-0 w-full h-full rounded object-cover"
                  />
                ) : (
                  <span className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-gray-300 text-xl">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 mr-1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Thumbnail 1 will appear here
                  </span>
                )}
              </div>
            </div>
            {thumbnailUrls.image1 && (
              <div className="mt-4 flex justify-center space-x-4">
                <button
                  onClick={downloadImage}
                  className="flex items-center bg-blue-600 hover:bg-blue-700 opacity-70 text-white px-4 py-2 rounded-lg shadow transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mr-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.478 5.559A1.5 1.5 0 0 1 6.912 4.5H9A.75.75 0 0 0 9 3H6.912a3 3 0 0 0-2.868 2.118l-2.411 7.838a3 3 0 0 0-.133.882V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0 0 17.088 3H15a.75.75 0 0 0 0 1.5h2.088a1.5 1.5 0 0 1 1.434 1.059l2.213 7.191H17.89a3 3 0 0 0-2.684 1.658l-.256.513a1.5 1.5 0 0 1-1.342.829h-3.218a1.5 1.5 0 0 1-1.342-.83l-.256-.512a3 3 0 0 0-2.684-1.658H3.265l2.213-7.191Z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M12 2.25a.75.75 0 0 1 .75.75v6.44l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 0 1 1.06-1.06l1.72 1.72V3a.75.75 0 0 1 .75-.75Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Download Image
                </button>
                <button
                  onClick={resetState}
                  className="flex items-center bg-purple-700 hover:bg-purple-700 opacity-70 text-white px-4 py-2 rounded-lg shadow transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mr-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Reset
                </button>
                <button 
                  onClick={() => thumbnailUrls.image1 && editWithPhotopea(thumbnailUrls.image1)}
                  className="flex items-center bg-green-800 hover:bg-green-700 text-white px-4 py-2 rounded-lg shadow transition-colors"
                >
                  <img
                    src={photopea_logo}
                    alt="photopea_logo"
                    className="w-6 h-6 mr-1"
                  />

                 
                  Edit with Photopea
                </button>
              </div>
            )}

            <div className="p-4 mb-4 mt-4 bg-gray-700 rounded-lg shadow-lg border-4 border-dashed border-gray-500 flex items-center justify-center ">
              <div
                style={{ width: '100%', paddingTop: '57.73%' }}
                className="relative"
              >
                {thumbnailUrls.image2 ? (
                  <img
                    src={thumbnailUrls.image2}
                    alt="Thumbnail"
                    className="absolute top-0 left-0 w-full h-full rounded object-cover"
                  />
                ) : (
                  <span className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-gray-300 text-xl">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 mr-1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Thumbnail 2 will appear here
                  </span>
                )}
              </div>
            </div>
            {thumbnailUrls.image2 && (
              <div className="mt-4 flex justify-center space-x-4">
                <button
                  onClick={downloadImage}
                  className="flex items-center bg-blue-600 hover:bg-blue-700 opacity-70 text-white px-4 py-2 rounded-lg shadow transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mr-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.478 5.559A1.5 1.5 0 0 1 6.912 4.5H9A.75.75 0 0 0 9 3H6.912a3 3 0 0 0-2.868 2.118l-2.411 7.838a3 3 0 0 0-.133.882V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0 0 17.088 3H15a.75.75 0 0 0 0 1.5h2.088a1.5 1.5 0 0 1 1.434 1.059l2.213 7.191H17.89a3 3 0 0 0-2.684 1.658l-.256.513a1.5 1.5 0 0 1-1.342.829h-3.218a1.5 1.5 0 0 1-1.342-.83l-.256-.512a3 3 0 0 0-2.684-1.658H3.265l2.213-7.191Z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M12 2.25a.75.75 0 0 1 .75.75v6.44l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 0 1 1.06-1.06l1.72 1.72V3a.75.75 0 0 1 .75-.75Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Download Image
                </button>
                <button
                  onClick={resetState}
                  className="flex items-center bg-purple-700 hover:bg-purple-700 opacity-70 text-white px-4 py-2 rounded-lg shadow transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mr-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Reset
                </button>
                <button 
                  onClick={() => thumbnailUrls.image2 && editWithPhotopea(thumbnailUrls.image2)}
                  className="flex items-center bg-green-800 hover:bg-green-700 text-white px-4 py-2 rounded-lg shadow transition-colors"
                >
                  <img
                    src={photopea_logo}
                    alt="photopea_logo"
                    className="w-6 h-6 mr-1"
                  />

                 
                  Edit with Photopea
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ThumbnailCreator;