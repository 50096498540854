import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';

const PostLoginScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hashFragment = window.location.hash.substring(1);
    const params = new URLSearchParams(hashFragment);
    const accessToken = params.get('access_token');

    if (accessToken) {
      localStorage.setItem('accessToken', accessToken.toString());

      setTimeout(() => {
        navigate('/dashboard');
      }, 2000); // Redirect after 2 seconds
    } else {
      setTimeout(() => {
        navigate('/linkchannel');
      }, 2000); // Redirect after 2 seconds
    }
  }, [navigate]);

  return (
    <div className=" animated-gradient  flex items-center justify-center h-screen bg-gradient-to-br from-purple-900 to-purple-700">
      <div className="text-white text-center">
        <h1 className="text-4xl mb-4">
          {window.location.href.includes('access_token')
            ? 'Login successful'
            : 'Something went wrong'}
        </h1>
        <p className="text-xl">
          {window.location.href.includes('access_token')
            ? "Fetching your Channel's Latest Data ..."
            : ' Redirecting to Login Page'}
        </p>
        <div className="ripple"></div>
      </div>
    </div>
  );
};

export default PostLoginScreen;
