import axios from 'axios';
import React, { useState } from 'react';
import { MainDataItem } from '../App';
import Tooltip from './atoms/Tooltip';
import infoIcon from '../assets/icons/icons8-info-48.svg';
import VideoBlock from './atoms/VideoBlock';
import useLoaderStore from '../zustandstore/store';

interface VideoIdea {
  title: string;
  outline: string;
}

interface VideoIdeaProps {
  userData: MainDataItem | null;
}

const VideoIdea: React.FC<VideoIdeaProps> = ({ userData }) => {
  const [videoIdea, setVideoIdea] = useState<VideoIdea[] | null>(null);
  const { loading, setLoading } = useLoaderStore();
  const [nudge, setNudge] = useState<string>('');
  const [showNudgeTooltip, setShowNudgeTooltip] = useState(false);
  const [showSwitchTooltip, setShowSwitchTooltip] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [useVideoTitles, setUseVideoTitles] = useState<boolean>(true); // State to control the title visibility

  const [showTitle, setShowTitle] = useState<boolean>(true); // State to control the title visibility
  const fetchIdea = async () => {
    const url = '/ideas';
    setLoading(true);

    axios
      .post(url, { nudge, useVideoTitles })
      .then((response) => {
        response.data = JSON.parse(response.data);
        setVideoIdea(response.data.ideas);
        setShowTitle(false); // Hiding the title
        setGenerated(true);

        setLoading(false);
      })
      .catch((error) => {
        alert('Something went wrong. Please reset and try again:');
        console.error('Error:', error);
        setLoading(false);
      });
  };

  return (
    <div className="w-full  flex flex-col items-center justify-center p-4  mx-auto">
      <div className="grid grid-cols-2 gap-2">
        {videoIdea?.map((idea, index) => (
          <div
            className="bg-white bg-opacity-10 backdrop-blur-md shadow-lg p-6 mb-6 rounded-lg"
            key={index}
          >
            <VideoBlock
              title={idea.title}
              summary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel velit id ipsum consequat laoree..."
              views="100k views"
              timestamp="1 hour ago"
              userProfile={userData?.snippet.thumbnails.medium.url || ''}
              channelName={userData?.snippet.title || ''}
            />
            <div className="mt-4">
              <h2 className=" font-semibold text-gray-300 mb-2">Details</h2>
              <p className="text-sm text-gray-300 bg-gray-800 p-3 rounded-md">
                {idea.outline}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full flex flex-col items-center justify-center">
        {showTitle && (
          <div className="text-center mb-6">
            <h1 className="text-3xl font-bold text-gray-300 mb-2">
              Auto Generate Your Video Ideas
            </h1>
            <p className="text-gray-400 text-lg">
              Idea Suggestions based on your previous uploads and analytics data
            </p>
          </div>
        )}

        <div className="relative flex-grow mb-4 w-full h-full max-w-xl ">
          <div className="flex items-center">
            <div className="flex-grow pr-2">
              <input
                className="w-full h-12 pl-10 pr-4 rounded-lg bg-gray-700 text-white text-lg"
                type="text"
                placeholder="Nudge (optional)"
                value={nudge}
                onChange={(e) => setNudge(e.target.value)}
              />
              <div
                className="absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onMouseEnter={() => setShowNudgeTooltip(true)}
                onMouseLeave={() => setShowNudgeTooltip(false)}
              >
                <img src={infoIcon} alt="Info" className="w-6 h-6" />
                {showNudgeTooltip && (
                  <Tooltip content="Enter your idea suggestions here. eg - The video should be targeted for children" />
                )}
              </div>
            </div>

            {/* <div className="flex items-center">
              <input
                type="checkbox"
                checked={useVideoTitles}
                onChange={(e) => setUseVideoTitles(e.target.checked)}
              />
              <label className="ml-2 text-gray-300">
                Refer Previous Videos
              </label>
            </div> */}
          </div>
        </div>

        <button
          className="w-full bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 text-lg rounded-md transition-colors max-w-lg  "
          onClick={fetchIdea}
        >
          {generated ? 'Regenerate' : 'Generate New Idea'}
        </button>
      </div>
    </div>
  );
};

export default VideoIdea;
