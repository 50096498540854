import create from 'zustand';

type State = {
  isYouTubeDataLatest: boolean;
  setIsYouTubeDataLatest: (value: boolean) => void;
};

const useAppStore = create<State>((set) => ({
  isYouTubeDataLatest: false,
  setIsYouTubeDataLatest: (value) => set({ isYouTubeDataLatest: value }),
}));

export default useAppStore;
