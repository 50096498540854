import image1 from '../../assets/thumbnail/gallery/1.webp';
import image2 from '../../assets/thumbnail/gallery/2.png';
import image3 from '../../assets/thumbnail/gallery/3.webp';
import image4 from '../../assets/thumbnail/gallery/4.webp';
import image5 from '../../assets/thumbnail/gallery/5.webp';
import image6 from '../../assets/thumbnail/gallery/6.png';
import image7 from '../../assets/thumbnail/gallery/7.png';
import image8 from '../../assets/thumbnail/gallery/8.webp';
import image9 from '../../assets/thumbnail/gallery/9.png';
import image10 from '../../assets/thumbnail/gallery/10.jpg';
const thumbnails = [
  {
    id: 1,
    image: image1,
    textPrompt:
      "A dynamic and high-energy thumbnail featuring a Lego character dressed in Fortnite gear, surrounded by holiday elements like snowflakes, Christmas trees, and bright lights. The color scheme is red, green, and white, embodying festive spirit. The prominent text 'Playing Lego Fortnite' in a toy-like or blocky font with a touch of Christmas decor, like a Santa hat on one of the letters. The background is dynamic and colorful, celebrating both Lego and Fortnite themes within a Christmas setting, creating a perfect holiday gaming atmosphere",
  },
  {
    id: 2,
    image: image2,
    textPrompt:
      "A dynamic thumbnail featuring a photorealistic neck-up photo of a Caucasian man wearing a black shirt. He's positioned on the left side of the image, in front of a green blackboard that takes up the background. On the blackboard is a high-contrast, rising bar graph illustrated with white chalk, indicating success or growth. The colors are vibrant to ensure the elements stand out, with particular attention paid to making the man and the graph easily visible against the green blackboard background. Jumbo large sized elements . ",
  },
  {
    id: 3,
    image: image3,
    textPrompt:
      "A dynamic thumbnail featuring a man wearing a modern virtual reality headset, immersed in a vividly rendered block-based game world. The man stands central in the frame, with a look of engagement and excitement, representing a first-person perspective. The style of the game world should depict the iconic blocky landscapes made of green grass blocks, brown dirt blocks, and a bright blue sky, closely resembling the game's aesthetic. The modern VR headset is photorealistic, providing a clear and accurate portrayal. Minimal text, 'META QUEST Review', in a modern sans-serif font is located in the top or bottom third of the image. The overall composition balances realism with the pixelated game environment, to attract viewers interested in virtual reality and gaming",
  },
  {
    id: 4,
    image: image4,
    textPrompt:
      "A dynamic thumbnail for a tutorial video featuring a Caucasian man seated at a desk in a normal room. He is showing a shocked and excited facial expression as money rains down around him, symbolizing financial success. A monitor displaying graphs that imply profitability is clearly visible on the desk. The overall atmosphere should be that of a casual and achievable home-based business situation. The color palette is vibrant but not overwhelming, with greens (representing money) standing out against a neutral background of the room. The text MUST ONLY BE - 'Dropshipping 2024' is bold, very large, and clearly stands out in the image's foreground. The scene should be aspirational and emblematic of making money from home with dropshipping, aiming to engage and inspire potential entrepreneurs.",
  },
  {
    id: 5,
    image: image5,
    textPrompt:
      "A dynamic thumbnail featuring a large, center-focused vial containing a red glowing liquid, prominently displaying a white triangle on its surface to evoke the YouTube play button's imagery. Surround the vial with a full black background to ensure high contrast and visibility. Behind the vial, integrate a green upwards-moving chart, symbolizing growth and success on YouTube. The colors should be vibrant, with the red of the liquid and the green of the chart standing out starkly against the black background. The thumbnail composition should be straightforward yet impactful, instantly conveying the idea of a potent formula for success on the video platform",
  },
  {
    id: 6,
    image: image6,
    textPrompt:
      "A dynamic thumbnail photo featuring a woman with long hair  , similing. She is holding a pink cream tube  prominently in her hand and is pointing to it with one of her fingers. The background must only be a soft, neutral color, ensuring the product and the woman are the focal points. The woman's pose should appear gentle, suggesting the beneficial qualities of the cream. grainy film",
  },
  {
    id: 7,
    image: image7,
    textPrompt:
      'A dynamic thumbnail featuring a photorealistic image of a simple, normal man with a confused expression, sitting in a room. In focus, the man is in the center with a large, clear question mark above his head. In his right hand, he holds a large red glowing sphere, and in his left, a large blue glowing cube. The room is minimally decorated to not distract from the central theme of decision-making. Despite the glowing objects, the color scheme of the room remains neutral, with emphasis on the realism of the man and his expressions. The overall composition is clean, with large elements to ensure clarity and focus on the theme of choice, represented by the spheres, cube, and question mark. Background elements are subtle and do not compete with the main focal points.',
  },
  {
    id: 8,
    image: image8,
    textPrompt:
      "A dynamic and high-energy thumbnail featuring sneakers that resemble the Fear of God Athletics Adidas style. Include imagery suggestive of unboxing a box, like an open box or wrapping paper, set against a vibrant background. The color scheme hints at the Adidas brand, using black, white, and other associated colors. The high contrast text MUST ONLY BE- 'UNBOXING ADIDAS SNEAKERS' is bold, giant, and prominently displayed. The layout is clean yet engaging, capturing the excitement and anticipation of unboxing new sneakers, appealing to sneaker enthusiasts and viewers interested in fashion and sports footwear. background is dynamic",
  },
  {
    id: 9,
    image: image9,
    textPrompt:
      "A dynamic thumbnail featuring a phto of a woman with an oval face and long brown hair inside a messy room filled with boxes, papers, cables, and general clutter. She is wearing a normal grey t-shirt and her hands are crossed in front of her in a 'no' gesture, signifying rejection towards the disarray around her. The background is a chaos of unorganized objects to clearly represent the mess mentioned in the title 'Stop living in a mess.' Her expression is determined and a little frustrated, emphasizing the need for change. The color scheme maintains realistic tones, with the grey of her shirt contrasting against the multicolored disarray. Large fonts with the concise text MUST SAY - `NO MORE MESS!` are overlaid on the thumbnail, capturing the essence of the content's message. dslr image",
  },
  {
    id: 10,
    image: image10,
    textPrompt:
      'An illustration of a man in a close-up, standing in front of a transition between two worlds, with the dividing line behind him and a large white jumbo arrow pointing from left to right in the center of the composition. He is wearing a t-shirt and glasses. The left side shows a programming office space with muted grey and dim blue colors, and the right side shows a vibrant farm with warm colors, green fields, and blue skies. The arrow should symbolize the transition or decision from a tech career to the life of a farmer, captured in a grainy film style',
  },
];

export default thumbnails;
