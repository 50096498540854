/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import axios from 'axios';
import { createClient } from '@supabase/supabase-js';
import useLoaderStore from '../../zustandstore/store';

const supabaseUrl = 'https://kmycdexspalbhmonhodv.supabase.co';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY; // Use your Supabase key

const supabase = createClient(supabaseUrl, supabaseKey || '');

const FaceSwap = () => {
  const [targetImage, setTargetImage] = useState<File | null>(null);
  const [sourceImage, setSourceImage] = useState<File | null>(null);
  const [resultImage, setResultImage] = useState<string>('');
  const { loading, setLoading } = useLoaderStore();

  const uploadImageToSupabase = async (
    imageFile:
      | string
      | File
      | Blob
      | ArrayBuffer
      | ArrayBufferView
      | Buffer
      | FormData
      | NodeJS.ReadableStream
      | ReadableStream<Uint8Array>
      | URLSearchParams
  ) => {
    if (!imageFile) return null;

    const fileExt =
      imageFile instanceof File ? imageFile.name.split('.').pop() : '';
    const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;

    const { data, error } = await supabase.storage
      .from('thumbnails')
      .upload(`uploads/${fileName}`, imageFile);

    if (error) {
      console.error('Error uploading to Supabase:', error, {
        cacheControl: '3600',
        upsert: false,
      });
      return null;
    }

    return `https://kmycdexspalbhmonhodv.supabase.co/storage/v1/object/public/thumbnails/uploads/${fileName}`;
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);
    let targetCdnUrl = null;
    let sourceCdnUrl = null;

    try {
      if (targetImage) {
        targetCdnUrl = await uploadImageToSupabase(targetImage);

        // Rest of the code...
      }
      if (sourceImage) {
        sourceCdnUrl = await uploadImageToSupabase(sourceImage);
        // Rest of the code...
      }
    } catch (error) {
      {
        setLoading(false);
        alert(
          'Error processing images , size of image should be less than 5MB. compress using https://squoosh.app/'
        );
      }
      console.error('Error uploading images to Supabase:', error);
      // Handle upload error...
    }

    if (targetCdnUrl && sourceCdnUrl) {
      try {
        const response = await axios.post('/imageprocess', {
          targetImageUrl: targetCdnUrl,
          sourceImageUrl: sourceCdnUrl,
        });
        console.log('API response:', response.data);
        setResultImage(response.data.image); // Set the result image URL
        setLoading(false);
        // Handle further logic...
      } catch (error) {
        setLoading(false);
        alert('Something went wrong. Please reset and try again:');
        console.error('Error processing images:', error);
        // Handle error...
      }
    } else {
      setLoading(false);

      alert(
        'Error processing images , size of image should be less than 5MB. compress using https://squoosh.app/'
      );
    }
  };

  const handleTargetImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setTargetImage(e.target.files[0]);
    }
  };

  const handleSourceImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSourceImage(e.target.files[0]);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-around  p-5 rounded-lg">
          <label className="flex flex-col  bg-gray-700 rounded-lg shadow-lg border-4 border-dashed border-gray-500 flex items-center justify-center p-10">
            <div className=""></div>
            <span className="mb-2">Face Image (Source)</span>
            <input
              type="file"
              onChange={handleSourceImageChange}
              className="rounded-md "
            />
          </label>
          <label className="flex flex-col  bg-gray-700 rounded-lg shadow-lg border-4 border-dashed border-gray-500 flex items-center justify-center p-10">
            <span className="mb-2">Thumbnail Image (Target Image)</span>
            <input
              type="file"
              onChange={handleTargetImageChange}
              className="rounded-md"
            />
          </label>
        </div>
        <div className="text-center mt-5">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg shadow transition-colors"
          >
            Upload Images
          </button>
        </div>
      </form>
      {resultImage && (
        <div className="text-center mt-5 mx-auto ">
          <img
            src={resultImage}
            alt="Result"
            className="max-w-full mx-auto bg-gray-700 rounded-lg shadow-lg border-4 border-dashed border-gray-500 p-2"
          />
        </div>
      )}
    </div>
  );
};

export default FaceSwap;
