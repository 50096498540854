import posthog from 'posthog-js';
import React, { useState } from 'react';
type ToneType = 'Casual' | 'Educative' | 'Chill' | 'Funny' | 'Formal';
type VideoType =
  | 'Default'
  | 'Tutorial'
  | 'Review'
  | 'Vlog'
  | 'Educational'
  | 'Entertainment';

const ScriptMaker = () => {
  const [topic, setTopic] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [tone, setTone] = useState<ToneType[]>([]);
  const [script, setScript] = useState<string>(
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(10); // default duration 10 minutes
  const [wordCount, setWordCount] = useState<number>(3500); // default word count for 10 minutes
  const [videoType, setVideoType] = useState<VideoType>('Default');
  const [language, setLanguage] = useState<string>('English');
  const [showCopyToast, setShowCopyToast] = useState<boolean>(false);
  const [showOtherLanguageInput, setShowOtherLanguageInput] =
    useState<boolean>(false);

  const handleToneChange = (selectedTone: ToneType) => {
    if (tone.includes(selectedTone)) {
      setTone(tone.filter((t) => t !== selectedTone));
    } else {
      setTone([...tone, selectedTone]);
    }
  };
  const handleLanguageChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLanguage(e.target.value);
    setShowOtherLanguageInput(e.target.value === 'Other');
  };

  const handleDurationChange = (newDuration: number) => {
    setDuration(newDuration);
    const wordsPerMinute = 350; // Assuming 350 words per minute
    setWordCount(newDuration * wordsPerMinute);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setScript('');
    posthog.capture('scriptmaker', { property: 'started' });
    console.log('stgate', {
      topic,
      comments,
      tone,
      videoType,
      language,
      wordCount,
    });

    try {
      let baseURL = process.env.REACT_APP_API_URL;
      if (baseURL === 'http://localhost:4000') {
        baseURL = 'http://localhost:4000/';
      }
      const response = await fetch(`${baseURL}scriptmaker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          topic,
          comments,
          tone,
          videoType,
          language,
          wordCount,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const reader = response?.body?.getReader();
      const decoder = new TextDecoder();

      // eslint-disable-next-line no-constant-condition
      while (true && reader) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        setScript((prev) => prev + chunk);
      }
    } catch (error) {
      alert('Something went wrong. Please reset and try again:');
      posthog.capture('scriptmaker', { property: 'error' });
      setScript('Error fetching script.');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(script);
      setShowCopyToast(true);
      setTimeout(() => setShowCopyToast(false), 3000); // Toast disappears after 3 seconds
    } else {
      // Fallback copy method for older browsers
      const textarea = document.createElement('textarea');
      textarea.value = script;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      // Show toast message here for "Script copied"
    }
  };

  return (
    <div className="relative flex h-screen px-12 text-white">
      <div className="flex flex-col w-1/2 space-y-6 pr-6">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col space-y-4">
            <h3 className="text-lg font-semibold">Details</h3>
            <input
              id="topic"
              placeholder="Topic"
              value={topic}
              className="rounded-lg p-3 bg-gray-700 text-white text-lg"
              onChange={(e) => setTopic(e.target.value)}
              required
            />
            <textarea
              id="comments"
              placeholder="Comments / Extra Information / Reference Text (optional)"
              value={comments}
              rows={4}
              className="rounded-lg p-3 bg-gray-700 text-white text-lg"
              onChange={(e) => setComments(e.target.value)}
            />
          </div>

          <div>
            <h3 className="text-lg font-semibold">Tone</h3>
            <div className="grid grid-cols-3 gap-4 py-2 rounded-lg ">
              {(
                [
                  'Casual',
                  'Educative',
                  'Chill',
                  'Funny',
                  'Formal',
                ] as ToneType[]
              ).map((toneValue) => (
                <button
                  type="button"
                  key={toneValue}
                  className={`rounded-lg p-2 text-sm font-semibold ${
                    tone.includes(toneValue)
                      ? 'bg-blue-600 hover:bg-blue-700'
                      : 'bg-gray-500 hover:bg-gray-600'
                  }`}
                  onClick={() => handleToneChange(toneValue)}
                >
                  {toneValue}
                </button>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold">Video Type</h3>
            <select
              id="videoType"
              value={videoType}
              onChange={(e) => setVideoType(e.target.value as VideoType)}
              className="rounded-lg p-2 bg-gray-700 text-white text-lg w-full"
            >
              <option value="Default">Default</option>
              <option value="Tutorial">Tutorial</option>
              <option value="Review">Review</option>
              <option value="Vlog">Vlog</option>
              <option value="Educational">Educational</option>
              <option value="Entertainment">Entertainment</option>
            </select>
          </div>

          <div>
            <h3 className="text-lg font-semibold">Language</h3>

            {showOtherLanguageInput ? (
              <input
                placeholder="Enter language"
                value={language}
                className="rounded-lg p-2 bg-gray-700 text-white text-lg w-full mt-2"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 20) {
                    setLanguage(inputValue);
                  }
                }}
              />
            ) : (
              <select
                id="language"
                value={language}
                onChange={handleLanguageChange}
                className="rounded-lg p-2 bg-gray-700 text-white text-lg w-full"
              >
                <option value="English">English 🇺🇸</option>
                <option value="Spanish">Spanish 🇪🇸 </option>
                <option value="Devnagri Hindi">Hindi 🇮🇳 </option>
                <option value="Turkish">Turkish 🇹🇷 </option>
                <option value="Other">Other (Type it in !) </option>
              </select>
            )}
          </div>

          <div>
            <h3 className="text-lg font-semibold">Duration</h3>
            <label htmlFor="duration" className="block">
              {duration} mins ( ~ {wordCount} words)
            </label>
            <input
              id="duration"
              type="range"
              min="5"
              max="15"
              value={duration}
              onChange={(e) => handleDurationChange(Number(e.target.value))}
              className="slider-class w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer"
            />
          </div>

          <button
            className="rounded-md p-3 bg-blue-600 hover:bg-blue-700 text-white w-full"
            type="submit"
            disabled={loading}
          >
            {loading ? 'Generating...' : 'Get Script'}
          </button>
        </form>
      </div>

      <div className="flex flex-col w-1/2 ml-6">
        <h2 className="text-2xl font-bold mb-4">Generated Script</h2>

        <textarea
          aria-label="Script output"
          className="h-3/4 w-full resize-none rounded-lg p-4 text-lg leading-loose font-mono bg-gray-700 text-white"
          value={script}
          readOnly
        />
        <button
          className="mt-4 self-end rounded-lg p-2 bg-blue-600 hover:bg-blue-700 opacity-70 text-white flex items-center space-x-2 transition-colors"
          onClick={copyToClipboard}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 mr-1"
          >
            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
            <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
          </svg>

          {showCopyToast ? 'Copied to Clipboard' : 'Copy'}
        </button>
      </div>
    </div>
  );
};

export default ScriptMaker;
