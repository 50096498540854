import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import Tooltip from './atoms/Tooltip';
import useAssistantStore from '../zustandstore/assistant';
import useChatStore from '../zustandstore/assistant';

interface ChatMessage {
  id: number;
  sender: 'user' | 'bot';
  content: string;
  asset?: string; // Add an optional property to hold file URLs;
  assetType: 'image' | 'file' | null;
}

interface SimpleModalProps {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
const SimpleModal: React.FC<SimpleModalProps> = ({
  show,
  onClose,
  children,
}) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex justify-center items-center">
      <div className="modal-content bg-slate-700 p-6 rounded-lg shadow-lg w-3/4 relative overflow-hidden">
        <div className="modal-body max-h-[80vh] overflow-auto">{children}</div>
        <button
          onClick={onClose}
          className="absolute bottom-2 right-2 p-2 bg-gray-200 rounded hover:bg-gray-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

// export default SimpleModal;

const Chat: React.FC = () => {
  // const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [threadId, setThreadId] = useState<string | null>(null);
  // const [assistantId, setAssistantId] = useState<string | null>(null);
  const [loadingText, setLoadingText] = useState('Crunching Numbers ..');
  const [presetBubblesVisible, setPresetBubblesVisible] = useState(true);
  // State to control the visibility of the modal
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    threadId,
    setThreadId,
    assistantId,
    setAssistantId,
    messages,
    addMessage,
  } = useChatStore();

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((prevText) => {
        switch (prevText) {
          case 'Crunching Numbers ..':
            return 'This will take a few minutes ...';
          case 'This will take a few minutes ...':
            return 'Thinking ...';
          case 'Thinking ...':
            return 'Crunching Numbers ..';
          default:
            return 'Crunching Numbers ..';
        }
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const exampleMessages = [
    'what videos are getting the most dislikes ?',

    'Give me a csv of all my vids which have more than 50 comments and their average view duration',
    'Make a chart of my views and likes',
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return;
    const newMessage: ChatMessage = {
      id: messages.length,
      sender: 'user',
      content: inputValue.trim(),
      assetType: null,
    };
    setPresetBubblesVisible(false);
    addMessage(newMessage);
    setInputValue('');
    setIsLoading(true);
    sendRequestToApi(newMessage.content);
  };

  const sendRequestToApi = (message: string) => {
    const url = '/analyticschat';
    setIsLoading(true);

    axios
      .post(url, { message, threadId, assistantId })
      .then((response) => {
        // Store threadId and assistantId if they are present in the response
        if (response.data.threadId) setThreadId(response.data.threadId);
        if (response.data.assistantId)
          setAssistantId(response.data.assistantId);

        // Process the response as before
        const botResponse: ChatMessage = {
          id: messages.length + 1, // Assuming id is just a sequential number
          sender: 'bot',
          content: response.data.response,
          asset: response.data.asset,
          assetType: response.data.assetType,
        };
        addMessage(botResponse); // Directly pass the botResponse object
        setIsLoading(false);
      })
      .catch((error) => {
        alert('Something went wrong. Please reset and try again:');
        console.error('Error:', error);
        setIsLoading(false);
      });
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSendMessage();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (messages.length === 0) {
      const botGreeting: ChatMessage = {
        id: 0,
        sender: 'bot',
        content:
          'Hi  👋🏼  ! How can I help you? ask me anything ! (the first reply might take a little more time because we are fetching the latest up-to-date information about your channel)',
        assetType: null,
      };
      addMessage(botGreeting); // Pass only the botGreeting object
    }
  }, []);

  // Function to toggle the modal visibility
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  return (
    <div className="flex flex-col h-full ">
      {/* <h2 className="text-white text-xl font-semibold mb-4">Chat</h2> */}
      {/* <ReactMarkdown>
        {`Just a link: [React](https://reactjs.com)`}
      </ReactMarkdown> */}
      <div className="flex-grow overflow-y-auto px-4 py-2">
        <SimpleModal show={isModalVisible} onClose={toggleModal}>
          <h2 className="text-2xl font-bold text-white mb-4">
            YouTubeGenius has access to all of these metrics from your YouTube
            channel:
          </h2>

          <div className="grid grid-cols-2 gap-4 text-white text-sm">
            <p>👁️ Views</p>
            <p>👍 Likes</p>
            <p>👎 Dislikes</p>
            <p>📊 Average View Percentage</p>

            <p>🖼️ Thumbnail URL</p>
            <p>📅 Published Date</p>
            <p>🔗 Shares</p>
            <p>⏱️ Average View Duration</p>
            <p>🆕 Subscribers Gained</p>
            <p>❌ Subscribers Lost</p>
            <p>📈 Annotation Impressions</p>
            <p>🖱️ Annotation Click-Through Rate</p>
            <p>💬 Comments Count</p>
          </div>

          <h2 className="text-xl font-bold text-white mt-6 mb-4">
            Explore by Asking These Prompts:
          </h2>

          <div className="text-white text-sm">
            <h3 className="font-semibold">🌟 Most Popular</h3>
            <ul className="grid grid-cols-2 gap-2">
              <li className="bg-slate-900 rounded p-2">
                Based on my channel, what video ideas do you have for me?
              </li>
              <li className="bg-slate-900 rounded p-2">
                How do I get more views?
              </li>
              <li className="bg-slate-900 rounded p-2">
                Can you audit my channel?
              </li>
              <li className="bg-slate-900 rounded p-2">
                Give me a csv of all my vids which have more than 50 comments
                and their average view duration
              </li>
              <li className="bg-slate-900 rounded p-2">
                Make a chart of all my videos with their views and likes{' '}
              </li>

              <li className="bg-slate-900 rounded p-2">
                Write a YouTube community post about:
              </li>
            </ul>

            <h3 className="font-semibold mt-4">🎥 Video Strategy</h3>
            <ul className="grid grid-cols-2 gap-2">
              <li className="bg-slate-900 rounded p-2">
                Can you audit my channel?
              </li>
              <li className="bg-slate-900 rounded p-2">
                Give me 5 video ideas about…
              </li>
              <li className="bg-slate-900 rounded p-2">
                How to grow a channel about…
              </li>
              <li className="bg-slate-900 rounded p-2">
                What video should I double down on?
              </li>
              <li className="bg-slate-900 rounded p-2">
                Write a script for a YouTube video about…
              </li>
              <li className="bg-slate-900 rounded p-2">
                Based on my channel, what video ideas do you have for me?
              </li>
            </ul>

            <h3 className="font-semibold mt-4">📊 Channel Analysis</h3>
            <ul className="grid grid-cols-2 gap-2">
              <li className="bg-slate-900 rounded p-2">
                Which of my videos have the highest engagement?
              </li>
              <li className="bg-slate-900 rounded p-2">
                How are my views and average view durations (AVD)?
              </li>
              <li className="bg-slate-900 rounded p-2">
                How do my video titles compare in terms of performance?
              </li>
              <li className="bg-slate-900 rounded p-2">
                What are my audience demographics, and how can I cater my
                content to them?
              </li>
            </ul>

            <h3 className="font-semibold mt-4">💬 Audience Engagement</h3>
            <ul className="grid grid-cols-2 gap-2">
              <li className="bg-slate-900 rounded p-2">
                How can I increase my channel’s subscriber count?
              </li>

              <li className="bg-slate-900 rounded p-2">
                Write a YouTube community post about…
              </li>
              <li className="bg-slate-900 rounded p-2">
                What types of videos do my subscribers like the most?
              </li>
              <li className="bg-slate-900 rounded p-2">
                How can I improve my audience retention rate?
              </li>
              <li className="bg-slate-900 rounded p-2">
                What can I do to encourage more comments and likes on my videos?
              </li>
            </ul>
          </div>
        </SimpleModal>
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex mb-2 ${
              message.sender === 'user' ? 'justify-end' : 'justify-start'
            }`}
          >
            {/* <div
              className={`p-2 rounded-lg ${
                message.sender === 'user'
                  ? 'bg-blue-500 self-end glassmorphism-chat-blue rounded-md rounded-br-none'
                  : 'bg-green-500 self-start glassmorphism-chat rounded-md rounded-tl-none'
              } text-white max-w-max relative`}
            > */}
            <div className="">
              <div
                className={`p-2 rounded-lg ${
                  message.sender === 'user'
                    ? 'bg-blue-500 self-end glassmorphism-chat-blue rounded-lg rounded-br-none'
                    : 'bg-green-500 self-start glassmorphism-chat rounded-lg rounded-tl-none'
                } text-white max-w-lg relative`}
              >
                <ReactMarkdown>{message.content.toString()}</ReactMarkdown>
                {message.assetType === 'image' && message.asset && (
                  <img
                    src={message.asset}
                    className=" max-w-md  mx-auto rounded-md mt-2 "
                    alt=""
                  />
                )}
              </div>
              {message.assetType === 'file' && message.asset && (
                <div
                  className=" bg-slate-700 rounded-lg text-white flex w-fit py-2 px-4 my-2 items-center cursor-pointer "
                  onClick={() => window.open(message.asset, '_blank')}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 mr-2"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p>Download File</p>
                </div>
              )}
            </div>
            {/* </div> */}
          </div>
        ))}

        {isLoading && (
          <div className=" flex items-center ">
            <div className="flex justify-start mb-2">{mySvgLoader()}</div>
            <p className=" ml-2 text-sm text-gray-400 ">{loadingText}</p>
          </div>
        )}
      </div>

      <div className="p-2  rounded-md ">
        {presetBubblesVisible && messages.length >= 1 && (
          <div className="p-2 flex rounded-md overflow-auto ">
            {exampleMessages.map((example, index) => (
              <button
                key={index}
                className="px-2 py-1 mr-2 mb-2 rounded-md glassmorphism-green opacity-70 text-white font-semibold text-sm"
                onClick={() => {
                  setInputValue(example);
                  handleSendMessage();
                  setPresetBubblesVisible(false); // Hide preset bubbles after sending a message
                }}
              >
                {`"${example}"`}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="p-1 border-t bg-gray-400 rounded-md w-full relative">
        {' '}
        {/* Add relative positioning for tooltip */}
        <form onSubmit={handleFormSubmit} className="flex items-center">
          <input
            type="text"
            className="flex-grow px-3 py-1 font-bold rounded-md bg-gray-400 focus:outline-none"
            placeholder="Type your message..."
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <button
            type="submit"
            className="px-4 py-2 ml-2 rounded-md bg-green-700 text-white font-bold flex items-center  focus:outline-none"
            disabled={isLoading}
          >
            {isLoading ? ' Loading ...' : 'Send'}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5 ml-2"
            >
              <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
            </svg>
          </button>
          <button
            type="submit"
            className="px-4 py-2 ml-2 rounded-md bg-blue-600 text-white flex items-center font-bold focus:outline-none"
            onClick={toggleModal}
          >
            Explore
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5 ml-2"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM6.262 6.072a8.25 8.25 0 1 0 10.562-.766 4.5 4.5 0 0 1-1.318 1.357L14.25 7.5l.165.33a.809.809 0 0 1-1.086 1.085l-.604-.302a1.125 1.125 0 0 0-1.298.21l-.132.131c-.439.44-.439 1.152 0 1.591l.296.296c.256.257.622.374.98.314l1.17-.195c.323-.054.654.036.905.245l1.33 1.108c.32.267.46.694.358 1.1a8.7 8.7 0 0 1-2.288 4.04l-.723.724a1.125 1.125 0 0 1-1.298.21l-.153-.076a1.125 1.125 0 0 1-.622-1.006v-1.089c0-.298-.119-.585-.33-.796l-1.347-1.347a1.125 1.125 0 0 1-.21-1.298L9.75 12l-1.64-1.64a6 6 0 0 1-1.676-3.257l-.172-1.03Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Chat;

const mySvgLoader = () => {
  return (
    <svg
      version="1.1"
      id="L5"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 0 0"
      xmlSpace="preserve"
      className="h-9 w-9" // Add CSS classes for height and width
    >
      <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 15 ; 0 -15; 0 15"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="#fff" stroke="none" cx="30" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 10 ; 0 -10; 0 10"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="#fff" stroke="none" cx="54" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 5 ; 0 -5; 0 5"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  );
};
