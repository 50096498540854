import React, { useState } from 'react';
import axios from 'axios';
import Tooltip from './atoms/Tooltip';
import infoIcon from '../assets/icons/icons8-info-48.svg';
import VideoBlock from './atoms/VideoBlock';
import { MainDataItem } from '../App';
import useLoaderStore from '../zustandstore/store';
import metatagimage from '../assets/metadata.png';

interface VideoMetadataProps {
  userData: MainDataItem | null;
}

const VideoMetadata = ({ userData }: VideoMetadataProps) => {
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [generated, setGenerated] = useState(false);

  const [metadata, setMetadata] = useState<{
    title: string;
    description: string;
    tags: string;
  } | null>(null);
  const { loading, setLoading } = useLoaderStore();
  const [showTitle, setShowTitle] = useState<boolean>(true); // State to control the title visibility

  const fetchMetadata = async () => {
    const url = '/metadata';
    setLoading(true);

    if (videoUrl) {
      axios
        .post(url, { videoUrl })
        .then((response) => {
          setMetadata(response.data);

          if (!response.data?.title) {
            alert(
              'Not transcript found for this video , Please Try with a Different URL'
            );
          }

          setShowTitle(false); // Hiding the title
          setGenerated(true);
          setLoading(false);
        })
        .catch((error) => {
          alert('Something went wrong. Please Try with a Different URL');
          console.error('Error:', error);
          setLoading(false);
        });
    } else {
      alert('Please enter a valid URL or ID');
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center p-4 max-w-2xl mx-auto">
      {metadata && (
        <div className="bg-white bg-opacity-10 backdrop-blur-md shadow-lg p-6 mb-6 rounded-lg overflow-auto">
          <VideoBlock
            title={metadata?.title || ''}
            summary={
              metadata.description
                ? metadata.description.split(' ').slice(0, 20).join(' ')
                : 'No description available'
            }
            views="100k views"
            timestamp="1 hour ago"
            userProfile={
              userData?.snippet.thumbnails.medium.url ||
              'https://placehold.co/400'
            }
            channelName={userData?.snippet.title || 'Channel name'}
          />

          <p className="text-sm text-gray-300 bg-gray-800 p-3 rounded-md h-40 overflow-auto mt-4">
            {metadata.description}
          </p>
          <p className="text-sm text-gray-300 bg-gray-800 p-3 rounded-md h-28 overflow-auto mt-4">
            {metadata.tags}
          </p>
        </div>
      )}

      <div className="w-full flex flex-col items-center justify-center">
        {showTitle && (
          <div className="text-center mb-6">
            <h1 className="text-3xl font-bold text-gray-300 ">
              MetaData Assistant
            </h1>
            <p className="text-gray-400 text-lg">
              Auto Generate your titles, descriptions, and tags . All SEO
              Optimised
            </p>
            <div className="text-white flex items-center justify-center w-full my-10">
              <div className="">
                <img src={metatagimage} className=" rounded-md" alt="" />
                <p className="text-gray-400 text-md">
                  Your link when you upload a video
                </p>
              </div>
              <p className=" text-2xl font-bold px-6 ">OR</p>
              <div className=" text-lg">
                <p>
                  Any video link{' '}
                  <span className="text-gray-400 text-sm">
                    ( It should have captions ){' '}
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="relative flex-grow mb-4 w-full">
          <input
            className="w-full h-12 pl-10 pr-4 rounded-lg bg-gray-700 text-white text-lg border border-transparent focus:border-blue-600"
            type="text"
            placeholder="Enter Video URL or Id"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            required
          />
          {/* Tooltip code... */}
        </div>
        <button
          className="w-full bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 text-lg rounded-md transition-colors"
          onClick={fetchMetadata}
        >
          {generated ? 'Regenerate' : 'Generate Metadata'}
        </button>
      </div>
    </div>
  );
};

export default VideoMetadata;
