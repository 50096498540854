import React from 'react';

interface TooltipProps {
  content: string;
}

const Tooltip: React.FC<TooltipProps> = ({ content }) => {
  return (
    <div
      className="absolute z-10 p-2 w-60 bg-black text-white rounded-md"
      style={{ bottom: '100%', transform: 'translateY(-5px)' }} // These styles push the tooltip upwards
    >
      {content}
    </div>
  );
};

export default Tooltip;
