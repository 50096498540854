import create from 'zustand';

interface ChatMessage {
  id: number;
  sender: 'user' | 'bot';
  content: string;
  asset?: string;
  assetType: 'image' | 'file' | null;
}

interface ChatStore {
  threadId: string | null;
  assistantId: string | null;
  messages: ChatMessage[];
  setThreadId: (threadId: string | null) => void;
  setAssistantId: (assistantId: string | null) => void;
  setMessages: (messages: ChatMessage[]) => void;
  addMessage: (message: ChatMessage) => void;
}

const useChatStore = create<ChatStore>((set) => ({
  threadId: null,
  assistantId: null,
  messages: [],

  setThreadId: (threadId) => set({ threadId }),
  setAssistantId: (assistantId) => set({ assistantId }),
  setMessages: (messages) => set({ messages }),
  addMessage: (message) =>
    set((state) => ({ messages: [...state.messages, message] })),
}));

export default useChatStore;
