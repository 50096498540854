import React from 'react';
import PropTypes from 'prop-types';
import AppLogo from '.././assets/fanfuellogo.png';
import YoutubeLogo from './../assets/logos/developed-with-youtube-sentence-case-light.png';
import { useNavigate } from 'react-router-dom';

interface LoginProps {
  clientId: string;
  scope: string;
}
const Login = ({ clientId, scope }: LoginProps) => {
  const redirectUri = process.env.REACT_APP_LINKCHANNEL_REDIRECT_URI || '';
  const navigate = useNavigate()

  const handleClick = () => {
    try {
      const appAccessToken = localStorage.getItem('appAccessToken');
      if (appAccessToken) {
        navigate('/linkchannel');
      } else {
        const params = new URLSearchParams({
          scope: scope,
          include_granted_scopes: 'true',
          response_type: 'token',
          state: 'state_parameter_passthrough_value',
          redirect_uri: redirectUri,
          client_id: clientId,
        });

        const url = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;

        window.location.href = url;
      }
    } catch (error) {
      console.error('Error during login:', error);
      // You can show an error message to the user or take appropriate action
    }
  };
  return (
    <div className=" bg-slate-900 h-screen flex flex-col items-center justify-center">
      {/* <div className="animated-gradient h-screen flex flex-col items-center justify-center"> */}
      <div className="border border-purple-500 border-opacity-30 p-10 pb-0 rounded-md relative">
        <img
          src={AppLogo}
          className=" h-20 w-20 rounded-md absolute top-10 left-1/2 transform -translate-x-1/2 -translate-y-full"
          alt="ff-logo"
        />
        <div className="flex-col mb-4 justify-center items-center">
          {/* <h1 className=" text-center text-xl text-white font-semibold">
            Sign up for FanFuel
          </h1> */}
        </div>
        <h1 className="text-xl text-white font-semibold py-2 text-center ">
          Sign in to FanFuel
        </h1>

        {/* Unhide once dev done */}

        <button
          onClick={handleClick}
          className="bg-white text-purple-900 rounded-md py-3 px-6 shadow-lg font-semibold flex items-center transform hover:scale-105 transition-transform duration-300 mx-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            className="h-6 w-6 mr-2"
          >
            <path
              fill="#FFC107"
              d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
            ></path>
            <path
              fill="#FF3D00"
              d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
            ></path>
            <path
              fill="#4CAF50"
              d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
            ></path>
            <path
              fill="#1976D2"
              d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
            ></path>
          </svg>
          Sign in with Google
        </button>

        {/* Maintainence  */}
        {/* <div className="">
          <div className=" bg-green-600 text-white text-lg px-2 py-2">
            Maintenance mode, our tech team is hard at work. Please check back
            in a few hours. 🛠️
          </div>
          <img
            src="https://i.imgur.com/j8iSx.jpeg"
            alt="cat working on pc pic"
            className="h-96 mx-auto mt-4  "
          />
        </div> */}
        <img
          src={YoutubeLogo}
          className=" h-20 mx-auto "
          alt="Developed with YouTube"
        />
      </div>

      <div className="mt-4 text-center text-white opacity-60 ">
        {' '}
        Learn how we use your data responsibly{' '}
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vRXcNVycCS022HvlW1E-a-MgeJZDyPXwKkuz2HWKaFOReHEo-YoKxzAyFKmfVbR8czWFisAhPvd7kIN/pub"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          here
        </a>
      </div>
    </div>
  );
};

Login.propTypes = {
  clientId: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
};

export default Login;
