import axios from 'axios';

const configureAxios = () => {
  axios.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // send the email from the local storage to the backend
    const email = localStorage.getItem('email');
    if (email) {
      config.headers.email = email;
    }

    // send the email from the local storage to the backend
    // const customChannelId = localStorage.getItem('customchannelid');
    // if (customChannelId && customChannelId !== '') {
    //   config.headers.customChannelId = customChannelId;
    // }

    const baseURL = process.env.REACT_APP_API_URL;

    if (config.url && config.url.startsWith('/api')) {
      config.baseURL = `${baseURL}/api`;
    } else {
      config.baseURL = baseURL;
    }

    return config;
  });
};

export default configureAxios;
