import React from 'react';

interface VideoBlockProps {
  title: string;
  summary: string;
  views: string;
  timestamp: string;
  userProfile: string;
  channelName: string;
}

const VideoBlock: React.FC<VideoBlockProps> = ({
  title,
  summary,
  views,
  timestamp,
  userProfile,
  channelName,
}) => {
  return (
    <div className="flex justify-center">
      <div className="relative h-44 w-80 bg-gray-800 rounded-lg overflow-hidden ">
        <p className="bg-black text-white text-xs font-semibold absolute bottom-2 right-2 p-1 rounded-md ">
          10:01
        </p>
      </div>
      <div className="ml-4">
        <h2 className="text-white text-xl font-bold mb-2 w-[400px] break-words">
          {title}
        </h2>
        <p className="text-white text-sm mb-2">
          {views} . {timestamp}
        </p>
        <div className="flex items-center">
          <img
            className="w-7 h-7 rounded-full mr-2"
            src={userProfile}
            alt="User Profile"
          />
          <p className="text-white text-sm">{channelName}</p>
          <svg className="ml-1 h-4 w-4" viewBox="0 0 24 24" fill="white">
            <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM9.8 17.3l-4.2-4.1L7 11.8l2.8 2.7L17 7.4l1.4 1.4-8.6 8.5z" />
          </svg>
        </div>
        <p className="text-white text-sm mt-2 w-[400px]">{summary + '..'}</p>
      </div>
    </div>
  );
};

export default VideoBlock;
