import React, { useEffect, useState } from 'react';
import Predict from './components/Predict';
import NavbarV2 from './components/Navbar';

// logos
import chatlogo from './assets/logos/icons8-chat-96.png';
import ideaslogo from './assets/logos/icons8-sparkling-96.png';
import metadatalogo from './assets/logos/icons8-for-you-96.png';
import thumbnaillogo from './assets/logos/icons8-gallery-96.png';
import scriptwriter from './assets/logos/icons8-pen-96.png';
import posthog from 'posthog-js';
import axios from 'axios';
import Suggestions from './components/Suggestions';
import VideoIdea from './components/Ideas';
import VideoMetadata from './components/MetaData';
import useLoaderStore from './zustandstore/store';
import useAppStore from './zustandstore/appstate';
import ThumbnailCreator from './components/ThumbnailCreator';
import ScriptMaker from './components/ScriptMaker';
export interface MainDataItem {
  kind: string;
  etag: string;
  id: string;
  snippet: {
    title: string;
    description: string;
    thumbnails: {
      default: {
        url: string;
        width: number;
        height: number;
      };
      medium: {
        url: string;
        width: number;
        height: number;
      };
      high: {
        url: string;
        width: number;
        height: number;
      };
    };
    localized: {
      title: string;
      description: string;
    };
  };
}

export interface VideoData {
  title: string;
  description: string;
  views: number;
  likes: number;
  dislikes: number;
  averageViewPercentage: number;
  subscribersGained: number;
  thumbnails: {
    default: {
      url: string;
      width: number;
      height: number;
    };
    medium: {
      url: string;
      width: number;
      height: number;
    };
    high: {
      url: string;
      width: number;
      height: number;
    };
  };
  publishedAt: string;
  isShort: boolean;
}

interface LoginResponse {
  mainData: {
    kind: string;
    etag: string;
    pageInfo: {
      totalResults: number;
      resultsPerPage: number;
    };
    items: MainDataItem[];
  };
  videos: VideoData[];
  email: string;
}

const App: React.FC = () => {
  const [activeTab, setActiveTab] = useState('ideas');
  const [userData, setUserData] = useState<MainDataItem | null>(null);
  const [videosData, setVideosData] = useState<VideoData[]>([]);
  // const [loading, setLoading] = useState(true); // Adding loading state
  const { loading, setLoading } = useLoaderStore();
  const { isYouTubeDataLatest, setIsYouTubeDataLatest } = useAppStore();

  useEffect(() => {
    const url = '/login';

    axios
      .get<LoginResponse>(url)
      .then((response) => {
        const { mainData, videos } = response.data;
        setUserData(mainData.items[0]);

        setVideosData(videos);
        saveEmailinLocalStorage(response.data);

        setIsYouTubeDataLatest(true);
        if (response?.data?.email) {
          posthog.identify(response?.data?.email, {
            email: response?.data?.email,
          });
          posthog.capture('user_logged_in', { email: response?.data?.email });
        }
      })
      .catch((error) => {
        console.log('error in login', error);
      });
  }, []);

  const saveEmailinLocalStorage = (data: LoginResponse) => {
    localStorage.setItem('email', data.email);
  };

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    const email = localStorage.getItem('email');
    posthog.capture('tab_clicked', {
      $set: {
        // This ensures these properties are set on the user profile as well
        tab_name: tabName,
        email: email || undefined,
      },
      tab_name: tabName,
      email: email || undefined,
    });
  };

  return (
    <div className=" bg-black h-screen flex flex-col  ">
      {/* ... */}
      <NavbarV2 userData={userData} />

      <div className="flex flex-grow overflow-hidden">
        {/* Sidebar */}
        <div className="sidebar bg-black p-2 w-56 min-h-full overflow-auto flex flex-col justify-between ">
          <div>
            <div
              className={`tab cursor-pointer p-2 mb-4 text-white ${
                activeTab === 'ideas' ? 'font-semibold glassmorphism' : ''
              }`}
              onClick={() => handleTabClick('ideas')}
            >
              <div className="flex items-center">
                <img src={ideaslogo} className="h-5 mr-2" alt="" />
                <h2>Ideas</h2>
                <p className=" bg-green-500 rounded-md w-fit mx-2 text-sm px-2 ">
                  New !
                </p>
              </div>
            </div>

            <div
              className={`tab cursor-pointer p-2 mb-4 text-white ${
                activeTab === 'scriptmaker' ? 'font-semibold glassmorphism' : ''
              }`}
              onClick={() => handleTabClick('scriptmaker')}
            >
              <div className="flex items-center">
                <img src={scriptwriter} className="h-5 mr-2" alt="" />
                <h2>ScriptWriter</h2>
              </div>
            </div>

            <div
              className={`tab cursor-pointer p-2 mb-4 text-white ${
                activeTab === 'thumbnail-create'
                  ? 'font-semibold glassmorphism'
                  : ''
              }`}
              onClick={() => handleTabClick('thumbnail-create')}
            >
              <div className="relative">
                <div className="flex items-center">
                  <img src={thumbnaillogo} className="h-5 mr-2" alt="" />
                  <h2>Thumbnail Studio</h2>
                </div>
              </div>
            </div>
            <div
              className={`tab cursor-pointer p-2 mb-4 text-white ${
                activeTab === 'metadata' ? 'font-semibold glassmorphism' : ''
              }`}
              onClick={() => handleTabClick('metadata')}
            >
              <div className="flex items-center">
                <img src={metadatalogo} className="h-5 mr-2" alt="" />
                <h2>Metadata Assistant</h2>
              </div>
            </div>

            <div
              className={`tab cursor-pointer p-2 mb-4 text-white ${
                activeTab === 'chat' ? 'font-semibold glassmorphism' : ''
              }   ${
                !isYouTubeDataLatest
                  ? 'cursor-not-allowed opacity-50'
                  : 'cursor-pointer'
              } `}
              onClick={() => {
                if (!isYouTubeDataLatest) return;
                handleTabClick('chat');
              }}
            >
              <div className="flex items-center">
                <img src={chatlogo} className="h-5 mr-2" alt="" />
                <h2>Chat</h2>
                {isYouTubeDataLatest ? null : (
                  <div className="flex justify-center items-center ml-2">
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className="">
                {isYouTubeDataLatest ? 'Latest' : 'Fetching latest data'}
              </div> */}

          <div className=" p-2 flex flex-col ">
            {/* <div className=" bg-slate-800 text-gray-300 w-fit px-4 py-2 my-2 rounded-lg shadow-md border border-1 border-purple-400  text-xs">
              <a
                href="https://discord.gg/b98YAgNNV8"
                target="_blank"
                rel="noreferrer"
                className=" flex items-center "
              >
                Support / Community{' '}
                <img src={discord} className="h-5 ml-2" alt="" />
              </a>
            </div> */}

            <a
              href="https://fanfuel.canny.io/feature-requests"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" bg-slate-800 text-gray-300 w-fit px-4 py-2 my-2 rounded-lg shadow-md border border-1 border-purple-400  text-xs">
                {' '}
                Support{' '}
              </div>
            </a>
          </div>
        </div>

        {/* Main Content */}
        <div className="main-content flex-1 p-4 overflow-auto bg-opacity-60 backdrop-blur-md">
          {activeTab === 'ideas' && <VideoIdea userData={userData} />}
          {activeTab === 'scriptmaker' && <ScriptMaker />}
          {activeTab === 'metadata' && <VideoMetadata userData={userData} />}
          {activeTab === 'thumbnail-create' && (
            <ThumbnailCreator userData={userData} />
          )}

          {activeTab === 'chat' && <Predict videosData={videosData} loading />}

          {/* {activeTab === 'ideas' && <Suggestions />} */}
          {/* {activeTab === 'reports' && <InDevMessagePage />} */}
          {/* TODO: Change this to <competitorAnalyze /> when you're ready */}
          {/* {activeTab === 'competitor-analysis' && <InDevMessagePage />} */}
        </div>
      </div>

      {loading && (
        <div className="absolute inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          {/* <img src={loader} alt="loading" /> */}
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default App;

// temporary api response
